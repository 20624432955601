/* eslint-disable max-len */
import React from 'react'
import './about-partners-style.scss'
import { useTranslation } from 'react-i18next'
// import { CarouselCards, CardProduct } from '../../components'
import { ImageRoundCorners } from '../../components/frames-images'
import src from '../../assets/images/careers/pacificross_careers_department_04.jpg'
// import urlBackground from '../../assets/images/tmp/pacificcross_products_01.jpeg'

// const fakeArray = [
//   {
//     link: '/',
//     promotion: 'promotion',
//     nameProduct: 'Blue Royale',
//     category: 'Individual and families',
//     conditions:
//       'Offers protection for the young age market aiming to have a well balanced lifestyle with coverage up to PHP 250,000.',
//     description:
//       'For people with only government insurance, basic health care and travellers',
//     urlBackground: { urlBackground },
//     outline: true,
//     border: true,
//   },
//   {
//     link: '/',
//     promotion: 'promotion',
//     nameProduct: 'Blue Royale',
//     category: 'Individual and families',
//     conditions:
//       'Offers protection for the young age market aiming to have a well balanced lifestyle with coverage up to PHP 250,000.',
//     description:
//       'For people with only government insurance, basic health care and travellers',
//     urlBackground: { urlBackground },
//     outline: true,
//     border: true,
//   },
//   {
//     link: '/',
//     promotion: 'promotion',
//     nameProduct: 'Blue Royale',
//     category: 'Individual and families',
//     conditions:
//       'Offers protection for the young age market aiming to have a well balanced lifestyle with coverage up to PHP 250,000.',
//     description:
//       'For people with only government insurance, basic health care and travellers',
//     urlBackground: { urlBackground },
//     outline: true,
//     border: true,
//   },
// ]

const AboutPartnersView = () => {
  const { t } = useTranslation()
  return (
    <div className="container-fluid aboutPartners__container">
      <div className="container-xxl">
        <div className="aboutPartners__head row">
          <div className="row blockHead__container">
            <div className="col-sm-7 col-12 blockHead__content">
              <h1 className="blockHead__title">Pacific Cross and Malacca Trust Insurance</h1>
              <p>Malacca Trust Insurance is partnered with Pacific Cross and have been focused on individuals and families. We set out this system in order for us to id nibh tortor id aliquet. Elit at imperdiet dui accumsan sit amet nulla facilisi morbi. Ut consequat semper viverra nam libero. Aenean et tortor at risus viverra adipiscing at. Amet luctus venenatis lectus magna fringilla urna. Id ornare arcu odio ut sem nulla. Egestas purus viverra accumsan in nisl nisi.</p>
            </div>
            <div className="col-sm-5 col-12 blockHead__image">
              <ImageRoundCorners src={src} roundBottomRight />
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl aboutPartners__body">
        <div className="">
          <h2>{t('aboutPartners.titlePlans')}</h2> 
        </div>
        <div className="row">
          {/* <CarouselCards
            data={fakeArray}
            arrows={false}
            Component={CardProduct}
            title="USD Individual Health Care Plan"
          /> */}
        </div>
        {/* <div className="row">
          <CarouselCards
            arrows={false}
            title="USD Individual Health Care Plan"/>
        </div>
        <div className="row">
          <CarouselCards
            arrows={false}
            title="USD Individual Health Care Plan"/>
        </div> */}
      </div>
      <div className="aboutPartners__footer"></div>
    </div>

  )
}

export default AboutPartnersView
