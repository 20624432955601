import React from 'react'
import classNames from 'classnames'
import './frames-images.scss'

const ImageRoundCorners = ({
  roundBottomRight,
  roundBottomRightHoz,
  roundBottomLefttHoz,
  roundBottomLeft,
  roundTopRight,
  roundTopLeft,
  addon,
  absolute,
  src,
}) => {
  const imageClass = classNames({
    imageRoundCorners__block__image: true,
    imageRoundCorners__roundBottomRight: roundBottomRight,
    imageRoundCorners__roundBottomLeft: roundBottomLeft,
    imageRoundCorners__roundTopLeft: roundTopLeft,
    imageRoundCorners__roundTopRight: roundTopRight,
    imageRoundCorners__imageRoundHoz: roundBottomRightHoz,
    imageRoundCorners__imageRoundHozLeft: roundBottomLefttHoz,
    imageRoundCorners__absolute: absolute,
  })

  const addonClass = classNames({
    addon,
  })

  return (
    <React.Fragment>
      <div className={imageClass}>
        <img src={src} />
      </div>
      <div className={addonClass}></div>
    </React.Fragment>
  )
}

export default ImageRoundCorners
